<template>
  <base-card class="products-page">
    <v-card-title>
      <div
        class="d-flex align-center"
        :class="isAdminUser ? 'justify-space-between' : 'justify-center'"
        style="width: 100%;"
      >
        <v-btn
          v-if="isAdminUser"
          color="primary"
          @click="addProduct"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("network.addProduct") }}
        </v-btn>

        <v-spacer v-if="isAdminUser" />

        <v-row class="filter-container">
          <v-col cols="6" sm="3">
            <v-select
              v-model="selectedNetwork"
              :items="networkList"
              :label="$t('price.network')"
              hide-details
              item-text="item"
              item-value="item"
            />
          </v-col>

          <v-col cols="6" sm="4">
            <v-select
              v-model="selectedCustomerType"
              :items="customerTypeList"
              :label="$t('price.customerType')"
              hide-details
              item-text="item"
              item-value="item"
              clearable
            />
          </v-col>

          <v-col cols="6" sm="2">
            <v-select
              v-model="selectedTerm"
              :items="termList"
              :label="$t('price.term')"
              hide-details
              item-text="label"
              item-value="value"
              clearable
            />
          </v-col>

          <v-col cols="6" sm="3">
            <v-select
              v-model="selectedType"
              :items="typeList"
              :label="$t('price.type')"
              hide-details
              item-text="label"
              item-value="value"
              clearable
            />
          </v-col>
        </v-row>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredProductList"
        :items-per-page="-1"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2"
      >
        <template v-slot:item.displayName="{item}">
          <span class="font-weight-bold">{{ item.displayName }}</span>
        </template>
        <template v-slot:item.serviceName="{item}">
          <div>{{ item.serviceName }}</div>
          <div v-if="item.serviceAlias">
            ({{ item.serviceAlias }})
          </div>
        </template>
        <template v-slot:item.isActive="{item}">
          <v-chip
            v-if="item.isActive"
            class="px-4"
            color="primary white--text"
          >
            <v-icon left>
              mdi-access-point-check
            </v-icon>
            {{ $t('common.active') }}
          </v-chip>
          <v-chip
            v-else
            class="px-4"
            color="danger white--text"
          >
            <v-icon left>
              mdi-block-helper
            </v-icon>
            {{ $t('common.inActive') }}
          </v-chip>
        </template>

        <template v-slot:item.networkAddOnList="{item}">
          {{ item.networkAddOnList ? item.networkAddOnList.length : '' }}
        </template>

        <template v-slot:item.action="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ma-1"
                fab
                x-small
                color="success"
                @click.native="editProduct(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common.edit") }} {{ $t("network.product") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'Products',
    },

    data () {
      return {
        spinnerText: '',
        productList: [],
        networkList: [
          'IP-ONLY',
          'OPENINFRA',
          'OPENUNIVERSE',
          'SOLLENTUNA',
          'STOKAB',
          'ZITIUS'
        ],
        selectedNetwork: null,
        customerTypeList: ['COMMERCIAL', 'RESIDENTIAL'],
        selectedCustomerType: null,
        headers: [{
          value: 'index',
          text: this.$t('common.number'),
          width: 70,
        }, {
          value: 'displayName',
          text: this.$t('network.networkDisplayName'),
        }, {
          value: 'type',
          text: this.$t('price.type'),
        }, {
          value: 'term',
          text: this.$t('price.term'),
        }, {
          value: 'speed',
          text: this.$t('price.speed'),
        }, {
          value: 'start',
          text: this.$t('price.startCost'),
        }, {
          value: 'price',
          text: this.$t('price.monthlyCost'),
        }, {
          value: 'isActive',
          text: this.$t('common.status'),
        }, {
          value: 'networkAddOnList',
          text: this.$t('network.addOns'),
        }, {
          value: 'nodeType',
          text: this.$t('price.nodeType'),
        }, {
          value: 'serviceName',
          text: this.$t('network.serviceName'),
        }, {
          text: 'Action',
          value: 'action',
          width: 100,
        }],
        typeList: [{
          value: 'BASIC',
          label: 'BASIC',
        }, {
          value: 'BASICPLUS',
          label: 'BASICPLUS',
        }, {
          value: 'PRO',
          label: 'PRO',
        }],
        selectedType: null,
        termList: [{
          value: 0,
          label: '0',
        }, {
          value: 12,
          label: '12',
        }, {
          value: 24,
          label: '24',
        }, {
          value: 36,
          label: '36',
        }],
        selectedTerm: null,
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        filterCondition: 'getFilterCondition',
      }),

      filteredProductList () {
        if (!this.selectedNetwork) {
          return []
        }

        let products = this.productList.filter(item => (item.network === this.selectedNetwork))

        if (this.selectedCustomerType) {
          products = products.filter(item => item.customerType === this.selectedCustomerType)
        }

        if (this.selectedTerm !== null) {
          products = products.filter(item => item.term === Number(this.selectedTerm))
        }

        if (this.selectedType) {
          products = products.filter(item => item.type === this.selectedType)
        }

        products = products.map((item, index) => ({
          ...item,
          index: index + 1,
        }))

        return products
      },
    },

    mounted () {
      if (this.filterCondition) {
        this.selectedNetwork = this.filterCondition.network
        this.selectedCustomerType =  this.filterCondition.customerType
        this.selectedTerm = this.filterCondition.term
        this.selectedType = this.filterCondition.type
      }

      this.loadProductList()
    },

    beforeDestroy () {
      this.setFilterCondition({
        network: this.selectedNetwork,
        customerType: this.selectedCustomerType,
        term: this.selectedTerm,
        type: this.selectedType,
      })
    },

    methods: {
      ...mapActions({
        fetchProductList: 'fetchProductList',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        setFilterCondition: 'setFilterCondition',
      }),

      async loadProductList() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        try {
          const productList = await this.fetchProductList()
          this.productList = productList.rows.map((product) => {
            let networkAddOnList = product.networkAddOnList

            if (product?.parentNetworkPrice?.networkAddOnList) {
              networkAddOnList = networkAddOnList.concat(product?.parentNetworkPrice?.networkAddOnList)
            }

            return {
              ...product,
              networkAddOnList,
              displayName: this.$tc('network.productTitle', !!product.nodeType, {
                network: product.network,
                speed: product.speed,
                type: product.type,
                term: product.term,
                nodeType: product.nodeType,
              }),
            }
          })

          if (!this.selectedNetwork) {
            this.selectedNetwork = this.networkList[0]
          }
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      editProduct(product) {
        this.$router.push(`/product/${product.id}`)
      },

      addProduct() {
        this.$router.push(`/product?networkType=${this.selectedNetwork}`)
      }
    },
  }
</script>

<style lang="scss">
  .products-page {
    .filter-container {
      max-width: 900px;
    }
  }
</style>
